.ImgForm {
    width: 70%;
    height: auto;
    margin: auto;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}


.form-body {
    width: 100%;
    display: flex;
    justify-content: center;
}

.no-image-display {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 1000px;
    width: 100%;
    max-height: 45vh;
    outline-style: dashed;
    outline-color: #F6F5F5;
    outline-width: 4px;
    border-radius: 10px;
}

.error-field {
    color: #F57A80;
}

#img-form {
    max-height: 45vh;
    position: relative;
}

#img-form:hover {
    cursor: pointer;
}

.form-image {
    width: auto;
    max-height: 45vh;
    max-width: 100%;
    border-radius: 10px;
    filter: brightness(100%);
    transition: filter 0.2s ease;
    background: white;
}

.form-image:hover {
    filter: brightness(70%);
}

.form-image:hover + .img-add {
    opacity: 1;
}

.img-add {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .2s ease;
    pointer-events: none;

    display: flex;
    align-items: center;
    justify-content: center;
}

.img-add > svg {
    width: 20%;
    height: auto;
}

.form-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#form_submit {
    width: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px
}



