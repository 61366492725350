body {
    background-color: #303440;
    color: #F6F5F5;
    min-height: 100vh;
}

a {
    color: #969CA7;
}

a:visited {
    color: #7C7D94;
}

.btn-primary {
    background-color: #3A7DBB !important;
    border-color: #3A7DBB !important;
}

.btn-primary:hover {
    background-color: #30689C !important;
    border-color: #30689C !important;
}

#root {
    min-height: 100%;
}