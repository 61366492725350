
.ResultsDisplay {
    width: 100%;
}

.safe {
    background-color: #B6E2A1;
    color: #303440;
    font-weight: 600;
}

.questionable {
    background-color: #FEBE8C;
    color: #303440;
    font-weight: 600;
}

.explicit {
    background-color: #F7A4A4;
    color: #303440;
    font-weight: 600;
}

.left-results {
    height: 100%;
    gap: 2rem;
}

.tag {
    border-radius: 5px;
    width: fit-content;
    padding: 10px;
    margin: 5px;
    text-align: center;
    font-size: large;
}

.tag-large {
    width: 90%;
    min-width: fit-content;
    padding: 8px;
    margin: 5px;
    text-align: center;
    text-wrap: wrap;
    border-radius: 5px;
    font-size: larger;
}

.tag-white {
    background-color: white;
    color: #303440;
    font-weight: 600;
}

.display-large-tags {
    align-items: center;
    text-align: center;
}

.display-large-tags-inside {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.left-results {
    min-width: 30%;
    margin-bottom: 2rem;
}


.right-results {
    width: 100%;
    text-align: center;
}