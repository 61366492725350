.App {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  padding: 1rem;
  height: 100%;
}

.progress-bar {
  min-width: 90%;
  min-height: 20px;
}

.loading-icon {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
      transform: scale(1) rotate(0deg);
  }
  to {
      transform: scale(1) rotate(360deg);
  }
}

.progress-section {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

footer {
  position:fixed;
  left:0px;
  bottom:2px;
  height:30px;
  width:100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem;
}